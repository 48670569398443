import mixpanel from 'mixpanel-browser';

interface Base {
  init(): void;
  registerIdentity(): void;
  track(eventName: string, eventData): void;
  setCampaignParams(): void;
}

class BaseMixpanelService implements Base {
  private token = process.env.MIXPANEL_TOKEN;
  protected isInitialized = false;

  async init() {
    await mixpanel.init(this.token);
    this.isInitialized = true;
  }

  async registerIdentity() {
    throw new Error('Not yet implemented');
  }

  async track(eventName: string, eventData: any) {
    if (!this.isInitialized) {
      await this.init();
    }
    try {
      await mixpanel.track(eventName, eventData);
    } catch (error) {
      console.error('Error: Mixpanel, ' + error.message);
    }
  }

  getQueryParam(url, param) {
    // Expects a raw URL
    param = param.replace(/[[]/, '[').replace(/[]]/, ']');
    var regexS = '[?&]' + param + '=([^&#]*)',
      regex = new RegExp(regexS),
      results = regex.exec(url);
    if (
      results === null ||
      (results && typeof results[1] !== 'string' && results[1].length)
    ) {
      return '';
    } else {
      return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
    }
  }

  setCampaignParams() {
    var campaign_keywords =
        'utm_source utm_medium utm_campaign utm_content utm_term'.split(' '),
      kw = '',
      params = {},
      first_params = {};
    var index;
    for (index = 0; index < campaign_keywords.length; ++index) {
      kw = this.getQueryParam(document.URL, campaign_keywords[index]);
      if (kw.length) {
        params[campaign_keywords[index] + ' [last touch]'] = kw;
      }
    }
    for (index = 0; index < campaign_keywords.length; ++index) {
      kw = this.getQueryParam(document.URL, campaign_keywords[index]);
      if (kw.length) {
        first_params[campaign_keywords[index] + ' [first touch]'] = kw;
      }
    }

    try {
      mixpanel.people.set(params);
      mixpanel.people.set_once(first_params);
      mixpanel.register(params);
    } catch (err) {
      console.error('Error: Mixpanel, ' + err.message);
    }
  }
}

export default new BaseMixpanelService();
export { BaseMixpanelService };

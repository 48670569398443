import { BaseMixpanelService } from 'src/common/services/mixpanel';

class MixpanelService extends BaseMixpanelService {
  async init() {
    await super.init();
    this.setCampaignParams();
  }

  async registerIdentity() {
    return;
  }

  async track(eventName: string, eventData = {}) {
    super.track(eventName, eventData);
  }
}

export default new MixpanelService();

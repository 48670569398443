export const addVersionToQuery = (path: string) => {
  if (path) {
    if (path.includes('?')) {
      return path + '&acceptVersion=v2.1';
    } else {
      return path + '?acceptVersion=v2.1';
    }
  }
  return path;
};

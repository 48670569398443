import { createRenderer } from 'fela';
import webPreset from 'fela-preset-web';

const fallbackRenderer = () => {
  return createRenderer({
    plugins: [...webPreset],
  });
};

export default fallbackRenderer;

export const SERVICE_SHORTCODE = 'NIPO';
export const IPO_STATUS = {
  active: 'active',
  preApply: 'preApply',
  closed: 'closed',
  listed: 'listed',
  upcoming: 'upcoming',
};
export const IPO_QUERY_STATUS = {
  active: 'active',
  preapply: 'preapply',
  closed: 'closed',
  listed: 'listed',
  upcoming: 'upcoming',
};

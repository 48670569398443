import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import * as Bowser from 'bowser';
import { addVersionToQuery } from './helpers';
import { IpoGenericResponse } from './types';
import { v4 as uuid } from 'uuid';

export interface HttpRequestHandlerInterface {
  post<Type>(path: string, object: Request): Promise<IpoGenericResponse<Type>>;
  patch<Type>(path: string, object: Request): Promise<IpoGenericResponse<Type>>;
  put<Type>(path: string, object: Request): Promise<IpoGenericResponse<Type>>;
  get<Type>(path: string): Promise<IpoGenericResponse<Type>>;
  delete<Type>(path: string, id: number): Promise<IpoGenericResponse<Type>>;
}

export const getDeviceDetails = () => {
  try {
    const browser = Bowser.parse(window.navigator.userAgent);
    return `platform=WEB|osName=${browser?.os?.name}/${browser?.os
      ?.version}|osVersion=${browser?.browser?.name}/${browser?.browser
      ?.version}|appVersion=${process.env.APP_VERSION || 1}|modelName=${browser
      ?.browser?.name}|manufacturer=unknown`;
  } catch (e) {
    return `platform=WEB|osName=unknown|osVersion=unknown|appVersion=${
      process.env.APP_VERSION || 1
    }|modelName=unknown|manufacturer=unknown`;
  }
};
export const SERVICE_SHORTCODE = 'NIPO';
export const getRequestCode = () => `${SERVICE_SHORTCODE}-${uuid()}`;

const handleServiceError = (error) => {
  //TODO: Handle 401, Server errors
  return {
    success: false,
    data: null,
    error: { code: '500', message: 'Something went wrong' },
  };
};

class HttpRequestHandler implements HttpRequestHandlerInterface {
  private client: AxiosInstance;

  protected createAxiosClient(): AxiosInstance {
    return Axios.create({
      baseURL: process.env.API_URL,
      withCredentials: true,
      responseType: 'json' as const,
      headers: {
        'Content-Type': 'application/json',
        'X-Device-Details': getDeviceDetails(),
        'accept-version': 'v2.1',
        'x-request-id': getRequestCode(),
      },
    });
  }

  constructor() {
    this.client = this.createAxiosClient();
  }

  async post<Type>(
    path: string,
    payload: any,
  ): Promise<IpoGenericResponse<Type>> {
    try {
      const response = await this.client.post<AxiosResponse>(
        addVersionToQuery(path),
        payload,
      );
      return response.data as unknown as IpoGenericResponse<Type>;
    } catch (error) {
      return handleServiceError(error);
    }
  }

  async patch<Type>(
    path: string,
    payload: any,
  ): Promise<IpoGenericResponse<Type>> {
    try {
      const response = await this.client.patch<AxiosResponse>(
        addVersionToQuery(path),
        payload,
      );
      return response.data as unknown as IpoGenericResponse<Type>;
    } catch (error) {
      return handleServiceError(error);
    }
  }

  async put<Type>(
    path: string,
    payload: any,
  ): Promise<IpoGenericResponse<Type>> {
    try {
      const response = await this.client.put<AxiosResponse>(
        addVersionToQuery(path),
        payload,
      );
      return response.data as unknown as IpoGenericResponse<Type>;
    } catch (error) {
      return handleServiceError(error);
    }
  }

  async get<Type>(path: string): Promise<IpoGenericResponse<Type>> {
    try {
      const response = await this.client.get<AxiosResponse>(
        addVersionToQuery(path),
      );
      return response.data as unknown as IpoGenericResponse<Type>;
    } catch (error) {
      return handleServiceError(error);
    }
  }

  async delete<Type>(path: string): Promise<IpoGenericResponse<Type>> {
    try {
      const response = await this.client.delete<AxiosResponse>(
        addVersionToQuery(path),
      );
      return response.data as unknown as IpoGenericResponse<Type>;
    } catch (error) {
      return handleServiceError(error);
    }
  }
}

export default new HttpRequestHandler();

import { SERVICE_SHORTCODE } from '../constants';
import { v4 as uuid } from 'uuid';

export function ipoDetailRoute(ipoId: string) {
  return `/open/v2/ipo/${ipoId}?seo=true`;
}

export function ipoDetailbySeoNameRoute(seoName: string) {
  return `/open/v2/ipo/seoName/${seoName}/?seo=true`;
}

export function getApplicationsRoute(
  ipoId: string,
  pageNumber = 1,
  records = 10,
) {
  return `/v2/applications?ipoId=${ipoId}&pageNumber=${pageNumber}&records=${records}?seo=true`;
}

export function getApplicationsRoutebySeoName(
  seoName: string,
  pageNumber = 1,
  records = 10,
) {
  return `/v2/applications?seoName=${seoName}&pageNumber=${pageNumber}&records=${records}?seo=true`;
}

export const validUPIListRoute = () => {
  return '/v2/upi/banks';
};

export const verifyUPIRoute = (handle: string) => {
  return `/v2/upi/verification?handle=${handle}`;
};

export const addApplicationRoute = () => {
  return `v2/application`;
};

export const deleteApplicationRoute = (applicationId: string) => {
  return `v2/application/${applicationId}`;
};

export const ipoListRoute = (status: string = '', months: number) => {
  return `/open/v2/ipos?status=${status}&months=${months || ''}&seo=true`;
};

import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import React, { createContext, FC, FunctionComponent, useEffect } from 'react';
import SessionStore from 'src/stores/session.store';

export const SessionContext = createContext(undefined);
export interface SessionContextProps {
  children: React.ReactNode;
  isIpoListed?: boolean;
  ipoId?: string;
}

const SessionContextProvider: FC<SessionContextProps> = ({
  isIpoListed,
  children,
  ipoId,
}: SessionContextProps) => {
  const { isAuthenticated } = SessionStore;
  const router = useRouter();

  useEffect(() => {
    if (isAuthenticated && !isIpoListed) {
      window.location.href = `${process.env.LOGIN_REDIRECT_URL}${
        ipoId ? `?ipoId=${ipoId}` : ''
      }`;
    }
  }, [router.pathname]);

  return (
    <SessionContext.Provider value={{ authenticated: isAuthenticated }}>
      {children}
    </SessionContext.Provider>
  );
};

export default observer(SessionContextProvider);

import {
  makeObservable,
  observable,
  action,
  runInAction,
  computed,
} from 'mobx';
import Cookies from 'universal-cookie';

export class SessionStore {
  userId = undefined;
  profileId = undefined;
  userType = undefined;
  cookies;

  constructor() {
    this.cookies = new Cookies();
    makeObservable(this, {
      userType: observable,
      isAuthenticated: computed,
    });
    this.userType = this.cookies.get('user_type');
    this.userId = this.cookies.get('user_id');
    this.profileId = this.cookies.get('profile_id');
  }

  public get isAuthenticated() {
    const userType = this.cookies.get('user_type');
    const profileId = this.cookies.get('profile_id');
    const ucc = this.cookies.get('user_id');
    if (
      (profileId && userType && userType.toLowerCase() === 'customer') ||
      ucc
    ) {
      return true;
    } else {
      return false;
    }
  }
}

export default new SessionStore();

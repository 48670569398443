/* eslint-disable */
import * as React from 'react';
import { RendererProvider } from 'react-fela';
import { IRenderer } from 'fela';
import fallbackRenderer from '../utils/fela/felaRenderer';

declare global {
  interface FelaProviderProps {
    children: React.ReactNode;
    renderer: IRenderer;
  }
}

const FelaProvider: React.FC<FelaProviderProps> = ({
  children,
  renderer,
  ...otherProps
}) => {
  /* eslint-disable react/jsx-no-useless-fragment */
  const fallback = fallbackRenderer();
  return (
    <RendererProvider renderer={renderer || fallback} {...otherProps}>
      <>{children}</>
    </RendererProvider>
  );
  /* eslint-enable */
};

export default FelaProvider;

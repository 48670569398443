import { makeObservable, observable, action, runInAction } from 'mobx';
import { enableStaticRendering } from 'mobx-react';
import { useMemo } from 'react';

import HttpRequestHandler from '../services/httpRequestHandler';
import {
  ipoDetailbySeoNameRoute,
  ipoDetailRoute,
  ipoListRoute,
} from './routes';
import { Ipo, IpoList } from './types';
import { IPO_STATUS } from '../constants';
import moment from 'dayjs';

enableStaticRendering(typeof window === 'undefined');

let ipoStore;
export class IpoStore {
  ipoDetails: Ipo = null;
  ipoId: string = null;
  seoName: string = null;
  ipoList: IpoList = null;
  iposMap: Map<string, Ipo> = null;
  upcomingIpos: Ipo[] = [];
  ongoingIpos: Ipo[] = [];
  listedIpos: Ipo[] = [];
  isLoadingIpoDetails = false;
  isLoadingIpoList = false;

  constructor() {
    makeObservable(this, {
      ipoDetails: observable,
      ipoId: observable,
      seoName: observable,
      iposMap: observable,
      isLoadingIpoDetails: observable,
      ipoList: observable,
      upcomingIpos: observable,
      ongoingIpos: observable,
      listedIpos: observable,
      fetchIpoDetails: action,
      fetchIpoDetailbySeoName: action,
      fetchIpoList: action,
      hydrate: action,
    });
    this.isLoadingIpoDetails = false;
    this.isLoadingIpoList = false;
    this.upcomingIpos = [];
    this.ongoingIpos = [];
    this.listedIpos = [];
    this.iposMap = new Map();
  }

  fetchIpoDetails = async (ipoId: string) => {
    this.ipoId = ipoId;
    runInAction(() => {
      this.isLoadingIpoDetails = true;
    });
    const response = await HttpRequestHandler.get<Ipo>(ipoDetailRoute(ipoId));
    if (response.success) {
      runInAction(() => {
        if (!this.iposMap.has(response.data.ipoId)) {
          this.updateIpo(response.data);
        }
        this.iposMap.set(response.data.ipoId, response.data);
        this.isLoadingIpoDetails = false;
      });
    }
  };

  fetchIpoDetailbySeoName = async (seoName: string) => {
    this.seoName = seoName;
    runInAction(() => {
      this.isLoadingIpoDetails = true;
    });
    const response = await HttpRequestHandler.get<Ipo>(
      ipoDetailbySeoNameRoute(seoName),
    );
    if (response.success) {
      runInAction(() => {
        if (!this.iposMap.has(response.data.seoName)) {
          this.updateIpo(response.data);
        }
        this.iposMap.set(response.data.seoName, response.data);
        this.isLoadingIpoDetails = false;
      });
    }
  };

  fetchIpoList = async (statusList: string[] = [''], months?: number) => {
    runInAction(() => {
      this.isLoadingIpoList = true;
    });
    let statusQuery = statusList.reduce((prev, curr) => {
      return prev + ',' + curr;
    }, '');
    if (statusQuery.length > 0) {
      statusQuery = statusQuery.slice(1, statusQuery.length);
    }
    const response = await HttpRequestHandler.get<IpoList>(
      ipoListRoute(statusQuery, months),
    );
    if (response.success) {
      runInAction(() => {
        this.isLoadingIpoList = false;
        this.ipoList = response.data;
        this.updateIpos(response.data?.ipos);
      });
    }
  };

  hydrate = (ipos) => {
    if (!ipos) return;

    runInAction(() => {
      this.updateIpos(ipos);
    });
  };

  updateIpos = (ipos) => {
    if (!ipos) return;
    this.clearData();
    ipos?.map((ipo) => {
      this.iposMap.set(ipo.ipoId, ipo);
      this.updateIpo(ipo);
    });
  };

  updateIpo = (ipo) => {
    if (ipo.status === IPO_STATUS.upcoming) {
      this.upcomingIpos.push(ipo);
    } else if (
      [IPO_STATUS.active, IPO_STATUS.preApply, IPO_STATUS.closed].includes(
        ipo.status,
      )
    ) {
      this.ongoingIpos.push(ipo);
    } else if (ipo.status === IPO_STATUS.listed) {
      this.listedIpos.push(ipo);
    }
    this.upcomingIpos = this.sortIpoData(this.upcomingIpos, 'ASC');
    this.ongoingIpos = this.sortIpoData(this.ongoingIpos);
    this.listedIpos = this.sortIpoData(this.listedIpos);
  };

  clearData = () => {
    this.upcomingIpos = [];
    this.ongoingIpos = [];
    this.listedIpos = [];
    this.iposMap = new Map();
  };

  sortIpoData = (ipos: Ipo[], sortOrder = 'DESC') => {
    ipos.sort((a, b) => {
      if (a.biddingStartDate && b.biddingEndDate) {
        if (sortOrder === 'DESC') {
          return moment(a.biddingStartDate).isBefore(moment(b.biddingEndDate))
            ? 1
            : -1;
        }
        return moment(a.biddingStartDate).isAfter(moment(b.biddingEndDate))
          ? 1
          : -1;
      }
      return 0;
    });
    return ipos;
  };
}

function initializeStore(initialState = null) {
  const _store = ipoStore ?? new IpoStore();

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here, check `pages/ssg.js` and `pages/ssr.js` for more details
  if (initialState) {
    _store.hydrate(initialState);
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!ipoStore) ipoStore = _store;

  return _store;
}

export function useIPOStore(initialState = undefined) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}

// export default new IpoStore();
